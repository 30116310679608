import React, { useState } from "react";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { subscribePackage } from "../../redux/slices/packageSlice";
import { AppDispatch, RootState } from "../../redux/store";
import { Package } from "../../models/Package";
import { useNavigate } from "react-router-dom";

type Props = {};

const SubscribePackage: React.FC = (props: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState<Package | null>(null);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const { packages } = useSelector((state: RootState) => state.packages);
  const { currentUser } = useSelector((state: RootState) => state.auth);

  const handleSelectPackage = (pkg: Package) => {
    setSelectedPackage(pkg);
  };

  const handleSubscribe = async () => {
    try {
      setIsLoading(true);
      if (!selectedPackage || !currentUser) return;

      const data = {
        packageId: selectedPackage.id,
        userId: currentUser.id,
      };

      const result = await dispatch(
        subscribePackage(data as { userId: number; packageId: number })
      ).unwrap();

      if (result.status === -1) {
        toast.error(result.message);
      } else {
        toast.success(result.message);
        setSelectedPackage(null);
        navigate("/whatsapp");
      }
    } catch (error: any) {
      toast.error(`Subscription failed: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container apps-container apps-email">
          <div className="nxl-content without-header nxl-full-content">
            <div className="main-content d-flex">
              <div
                className="content-area"
                data-scrollbar-target="#psScrollbarInit"
              >
                <div className="content-area-body">
                  <div className="row">
                    <div className="col-md-12 mx-auto">
                      <div className="card stretch stretch-full">
                        <div className="card-header flex-column">
                          <h5 className="text-center">
                            WhatsApp Plans and Pricing
                          </h5>
                          <p className="mb-0">
                            Choose a plan and start sending messages with ease.
                          </p>
                        </div>
                        <div className="card-body">
                          <div className="row">
                            {packages
                              ?.filter((pkg) => pkg.package_code !== "BASIC")
                              .map((pkg, index) => (
                                <div key={pkg.id} className="col-12 col-md-4 ">
                                  <div
                                    className={`p-4 mb-4 d-block ${
                                      index % 2 === 0
                                        ? "bg-soft-100"
                                        : "bg-soft-200"
                                    } border border-dashed border-gray-5 rounded-1 cursor-pointer`}
                                  >
                                    <div className="d-flex align-items-center justify-content-between w-100">
                                      <h6 className="fs-14 fw-bold">
                                        {pkg.name.toUpperCase()}
                                      </h6>
                                    </div>
                                    <p className="fs-12 mb-2 fw-normal text-black text-truncate-2-line">
                                      {pkg.description}
                                    </p>
                                    <div className="">
                                      <span className="fs-20 fw-bold text-dark">
                                        {pkg.price} €
                                      </span>{" "}
                                      /{" "}
                                      <em className="fs-11 fw-medium">Month</em>
                                    </div>
                                    <hr />
                                    <p className="fs-12 fw-bold text-primary">
                                      Features
                                    </p>
                                    <ul className="list-unstyled activity-feed">
                                      <li className="d-flex justify-content-between feed-item feed-item-success">
                                        <span className="fw-bold">
                                          Messages to send
                                        </span>
                                        <span className="fw-bold text-black">
                                          {pkg.totalMessages}
                                        </span>
                                      </li>
                                      <li className="d-flex justify-content-between feed-item feed-item-info">
                                        <span className="fw-bold">
                                          Number of extractions
                                        </span>
                                        <span className="fw-bold text-black">
                                          {pkg.totalExtractions}
                                        </span>
                                      </li>
                                      <li className="d-flex justify-content-between feed-item feed-item-warning">
                                        <span className="fw-bold">
                                          Total Device:
                                        </span>
                                        <span className="fw-bold text-black">
                                          {pkg.totalWhatsAppNumbers}
                                        </span>
                                      </li>
                                    </ul>
                                    <button
                                      className="btn btn-primary w-100 mt-3"
                                      data-bs-toggle="modal"
                                      data-bs-target="#subscribeModal"
                                      onClick={() => handleSelectPackage(pkg)}
                                    >
                                      Subscribe Now
                                    </button>
                                  </div>
                                </div>
                              ))}
                              <div className="col-12 col-md-4 ">
                                  <div
                                    className={`p-4 mb-4 d-block ${
                                      1 % 2 === 0
                                        ? "bg-soft-100"
                                        : "bg-soft-200"
                                    } border border-dashed border-gray-5 rounded-1 cursor-pointer`}
                                  >
                                    <div className="d-flex align-items-center justify-content-between w-100">
                                      <h6 className="fs-14 fw-bold">
                                        Personalize Package
                                      </h6>
                                    </div>
                                    <p className="fs-12 mb-2 fw-normal text-black">
                                      Wondering which package might fit your needs? With this flexible option,
                                  </p>
                                    <div className="fs-11 mb-2 fw-normal text-black">
                                      <span className="">
                                      </span>{" "}
                                      you have the freedom to design a plan that meets your exact business needs.
                                    </div>
                                    <hr />
                                    <p className="fs-12 fw-bold text-primary">
                                    customization rates
                                    </p>
                                    <ul className="list-unstyled activity-feed">
                                      <li className="d-flex justify-content-between feed-item feed-item-success">
                                        <span className="fw-bold">
                                          Amount per message
                                        </span>
                                        <span className="fw-bold text-black">
                                          0.0003 €
                                        </span>
                                      </li>
                                      <li className="d-flex justify-content-between feed-item feed-item-info">
                                        <span className="fw-bold">
                                          Amount per extraction
                                        </span>
                                        <span className="fw-bold text-black">
                                          1 €
                                        </span>
                                      </li>
                                    </ul>
                                    <div className="fs-11 mb-2 fw-normal text-black">
                                        <span className="fw-bold">
                                        Simply specify the number of messages and data extractions you need.
                                        </span>
                                        <span className="fw-bold text-black">
                                        </span>
                                      </div>
                                    <button
                                      className={`btn btn-primary w-100 mt-3
                                        ${!currentUser?.activeSubscriptions ? "disabled" : ""}`}
                                        onClick={() => navigate('/update-package')}
                                    >
                                      Create Your Custom Plan
                                    </button>
                                  </div>
                                </div>
                              {/* <div className="col-12 col-md-4">
                                <div
                                  className={`p-4 mb-4 d-block ${
                                    3 % 2 === 0 ? "bg-soft-100" : "bg-soft-200"
                                  } border border-dashed border-gray-5 rounded-1 cursor-pointer`}
                                >
                                  <div className="d-flex align-items-center justify-content-between w-100">
                                    <h6 className="fs-14 fw-bold">
                                      Personalize Your Package
                                    </h6>
                                  </div>
                                  <p className="fs-12 mb-2 fw-normal text-black">
                                  Wondering which package might fit your needs? With this flexible option, you have the freedom to design a plan that meets your exact business needs.
                                  </p>
                                  <p className="fs-12 fw-normal text-muted">
                                    Simply specify the number of messages and data extractions you need,
                                    and we'll calculate a custom package tailored just for you.
                                  </p>
                                  <hr />
                                  <div className="alert alert-info fs-12">
                                    <strong>Why Choose This Option?</strong>  
                                    Take control of your subscription by only paying for what you truly need.
                                    Avoid unnecessary expenses.
                                  </div>
                                  <button
                                    className={`btn btn-primary w-100 mt-4
                                    ${!currentUser?.activeSubscriptions ? "disabled" : ""}`}
                                    onClick={() => navigate('/update-package')}
                                  >
                                    Create Your Custom Plan
                                  </button>
                                </div>
                              </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
        <div
          className="modal fade"
          id="subscribeModal"
          tabIndex={-1}
          data-bs-keyboard="false"
          role="dialog"
        >
          <div
            className="modal-dialog modal-dialog-scrollable modal-dialog-centered"
            role="document"
          >
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="modalTitleId">
                  Subscribe to the {selectedPackage?.name.toUpperCase()} Plan
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <h6 className="fs-14">
                  Do you confirm subscribing to the{" "}
                  <span className="fw-bold">{selectedPackage?.name} Plan</span>?
                </h6>
                <div>
                  <h6 className="fs-14">
                    Price:{" "}
                    <span className="text-primary">
                      €{selectedPackage?.price}
                    </span>
                  </h6>
                  <p className="fs-14 mb-0">
                    The corresponding amount will be deducted from your balance.
                  </p>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-warning" data-bs-dismiss="modal">
                  Cancel
                </button>
                <button
                  id="btn-n-add"
                  className="btn btn-success"
                  disabled={isLoading}
                  data-bs-dismiss="modal"
                  onClick={handleSubscribe}
                >
                  {isLoading && <span className="loader"></span>}
                  subscribe
                </button>
              </div>
            </div>
          </div>
        </div>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default SubscribePackage;
