import React, { useEffect, useState } from "react";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import { UilAngleLeftB, UilPaperclip, UilPlus } from "@iconscout/react-unicons";
import { Link } from "react-router-dom";
import { pricingService } from "../../services/pricingService";
import toast from "react-hot-toast";
import { useFormik } from "formik";
import Select from "react-select";
import { pricingValidationSchema } from "../../validation/FormikValues";
import { smsService } from "../../services/smsService";
import PricingTable from "../../components/PricingTable";
import countryList from "react-select-country-list";

type Props = {};

const PricingList = (props: Props) => {
  const [loading, setLoading] = useState(false);
  const [pricings, setPricings] = useState<any[]>([]);
  const [selectedPricing, setSelectedPricing] = useState<any>({});

  const handleSelectPricing = (pricing: any) => {
    setSelectedPricing(pricing);
    formik.setValues({
      Country: pricing.Country,
      MNC: pricing.MNC ?? "",
      MCC: pricing.MCC ?? "",
      PhoneCode: pricing.PhoneCode ?? "",
      iso2: pricing.iso2 ?? "",
      PricePartner: pricing.PricePartner ?? 0,
      Price: pricing.Price ?? 0,
    });
  };

  const handleEditPricing = async () => {
    if (!selectedPricing) return;
    setLoading(true);
    try {
      await pricingService
        .editNewPricing(selectedPricing.MCC, formik.values)
        .then((res: any) => {
          if (res.status === -1) {
            toast.error(res.message);
            return;
          }
          toast.success(res.message);
          fetchPricings();
        })
        .catch((res: any) => {
          toast.error(res.message);
        })
        .finally(() => setLoading(false));
    } catch (error) {
      toast.error("An error occured during pricing edit");
    }
  };

  const formik = useFormik({
    initialValues: {
      Country: "",
      MNC: "",
      MCC: "",
      PhoneCode: "",
      iso2: "",
      PricePartner: 0,
      Price: 0,
    },
    validationSchema: pricingValidationSchema,
    onSubmit: handleEditPricing,
  });

  const fetchPricings = async () => {
    try {
      setLoading(true);
      const res = await smsService.adminGetAvailableCountries();
      if (res.status === -1) {
        return;
      }
      setPricings(res.data);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPricings();
  }, []);

  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container">
          <div className="nxl-content">
            <div className="page-header">
              <div className="page-header-left d-flex align-items-center">
                <div className="page-header-title">
                  <h5 className="m-b-10 border-0">Pricing List</h5>
                </div>
              </div>
              <div className="page-header-right ms-auto">
                <div className="page-header-right-items">
                  <div className="d-flex d-md-none">
                    <div className="page-header-right-close-toggle">
                      <UilAngleLeftB className="me-2" />
                      <span>Back</span>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-2 page-header-right-items-wrapper">
                    <div className="dropdown">
                      <div
                        className="btn btn-icon btn-light-brand"
                        data-bs-toggle="dropdown"
                        data-bs-offset="0, 10"
                        data-bs-auto-close="outside"
                      >
                        <UilPaperclip />
                      </div>
                      <div className="dropdown-menu dropdown-menu-end">
                        <div className="dropdown-item">
                          <i className="bi bi-filetype-pdf me-3"></i>
                          <span>PDF</span>
                        </div>
                        <div className="dropdown-item">
                          <i className="bi bi-filetype-csv me-3"></i>
                          <span>CSV</span>
                        </div>
                        <div className="dropdown-item">
                          <i className="bi bi-filetype-xml me-3"></i>
                          <span>XML</span>
                        </div>
                        <div className="dropdown-item">
                          <i className="bi bi-filetype-txt me-3"></i>
                          <span>Text</span>
                        </div>
                        <div className="dropdown-item">
                          <i className="bi bi-filetype-exe me-3"></i>
                          <span>Excel</span>
                        </div>
                        <div className="dropdown-divider"></div>
                        <div className="dropdown-item">
                          <i className="bi bi-printer me-3"></i>
                          <span>Print</span>
                        </div>
                      </div>
                    </div>
                    <Link className="btn btn-primary" to={"/add-pricing"}>
                      <UilPlus />
                      <span>Add Pricing</span>
                    </Link>
                  </div>
                </div>
                <div className="d-md-none d-flex align-items-center">
                  <div className="page-header-right-open-toggle">
                    <i className="feather-align-right fs-20"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="main-content">
              <PricingTable
                isAdmin={true}
                pricings={pricings}
                handleSelectPricing={handleSelectPricing}
              />
            </div>
          </div>
        </main>

        {/* delete modal  */}
        <div
          className="modal fade"
          id="deleteModal"
          tabIndex={-1}
          aria-labelledby="deleteModal"
          // aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Delete Pricing
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                Do you really want to delete the pricing{" "}
                {/* <span className="fw-bold">
                  {selectedClient?.firstname} {selectedClient?.lastname}
                </span>{" "} */}
                ?
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  //   onClick={handleDelete}
                  //   disabled={loading}
                  data-bs-dismiss="modal"
                >
                  {/* {loading && <span className="loader"></```tsx
                  {/* {loading && <span className="loader"></span>} */}
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
        {/*edit modal */}
        <div
          className="modal fade"
          id="editModal"
          tabIndex={-1}
          aria-labelledby="editModal"
          // aria-hidden="true"
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Edit Pricing
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="row">
                    <div className="mb-3 col-md-4">
                      <label htmlFor="Country">Country</label>
                      <Select
                        options={countryList().getData()} // Fetch country options
                        value={countryList()
                          .getData()
                          .find(
                            (option: { value: string; label: string }) =>
                              option.label === formik.values.Country
                          )}
                        onChange={(option) => {
                          formik.setFieldValue("Country", option.label); // Set only the value
                        }}
                        onBlur={formik.handleBlur}
                        name="Country"
                      />
                      {formik.touched.Country && formik.errors.Country ? (
                        <div className="text-danger">
                          {formik.errors.Country}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="iso2">Country ISO2</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Country ISO2"
                        id="iso2"
                        {...formik.getFieldProps("iso2")}
                      />
                      {formik.touched.iso2 && formik.errors.iso2 ? (
                        <div className="text-danger">{formik.errors.iso2}</div>
                      ) : null}
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="PhoneCode">Phone Code</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone Code"
                        id="PhoneCode"
                        {...formik.getFieldProps("PhoneCode")}
                      />
                      {formik.touched.PhoneCode && formik.errors.PhoneCode ? (
                        <div className="text-danger">
                          {formik.errors.PhoneCode}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="PricePartner">
                        Pricing Partner (EUR)
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Pricing Partner"
                        id="PricePartner"
                        {...formik.getFieldProps("PricePartner")}
                      />
                      {formik.touched.PricePartner &&
                      formik.errors.PricePartner ? (
                        <div className="text-danger">
                          {formik.errors.PricePartner}
                        </div>
                      ) : null}
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="Price">Price (EUR)</label>
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Price"
                        id="Price"
                        {...formik.getFieldProps("Price")}
                      />
                      {formik.touched.Price && formik.errors.Price ? (
                        <div className="text-danger">{formik.errors.Price}</div>
                      ) : null}
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="MCC">MCC</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="MCC"
                        id="MCC"
                        {...formik.getFieldProps("MCC")}
                      />
                    </div>
                    <div className="mb-3 col-md-4">
                      <label htmlFor="MNC">MNC</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="MNC"
                        id="MNC"
                        {...formik.getFieldProps("MNC")}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={() => formik.handleSubmit()}
                  disabled={!selectedPricing || loading}
                >
                  {loading && <span className="loader"></span>}
                  Save Changes
                </button>
              </div>
            </div>
          </div>
        </div>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default PricingList;
