import { User } from "../models/User";
import axiosInstance from "./axiosInstance";

const registerUser = async (user: Partial<User>) => {
  const response = await axiosInstance.post("auth/register", user);
  return response.data;
};

const confirmOtp = async (data: { email: string; otp: string }) => {
  const response = await axiosInstance.post("auth/verify-otp", data);
  return response;
};

const loginUser = async (credentials: { email: string; password: string }) => {
  const response = await axiosInstance.post("auth/login", credentials);
  if (response.data) {
    return response.data;
  } else {
    return response;
  }
};

const getCurrentUser = async () => {
  const response = await axiosInstance.get("auth/profile");
  return response.data;
};

const getOtp = async () => {
  const response = await axiosInstance.post("auth/get-otp");
  return response.data;
};

const updateUser = async (user: Partial<User>) => {
  const response = await axiosInstance.patch("user/update", user);
  return response.data;
};

const confirmEmail = async (data: { email: string }) => {
  const response = await axiosInstance.post("auth/confirm-email", data);
  return response.data;
};

const verifyEmailToken = async (data: { token: string }) => {
  const response = await axiosInstance.post("auth/verify-email-token", data);
  return response.data;
};

const resetPassword = async (data: { email: string; password: string }) => {
  const response = await axiosInstance.post("auth/reset-password", data);
  return response;
};


export const AuthService = {
  registerUser,
  loginUser,
  getCurrentUser,
  confirmOtp,
  getOtp,
  updateUser,
  confirmEmail,
  resetPassword,
  verifyEmailToken,
  
};
