import React, { useCallback, useEffect, useState } from "react";
import AuthLayout from "../../components/auth/AuthLayout";
import VerifiedGuard from "../../components/auth/VerifiedGuard";
import {
  UilWhatsapp,
  UilUsersAlt,
  UilArrowLeft,
} from "@iconscout/react-unicons";
import "react-phone-number-input/style.css";
import toast from "react-hot-toast";
import { whatsappService } from "../../services/whatsappService";
import { Socket } from "socket.io-client";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { Link } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import { SOCKET_EVENTS } from "../../utils/socketEvents";
import { logMessage } from "../../utils/logMessage";
import UserWhatsappSessions, {
  Session,
} from "../../components/whatsApp/UserWhatsappSessions";
import CreateSession from "../../components/whatsApp/CreateSession";
import WhatsappMessage from "../../components/whatsApp/WhatsappMssage";
import ExtractContacts from "../../components/whatsApp/ExtractContacts";
import WhatsappMessageGroup from "../../components/whatsApp/WhatsappMessageGroup";

type WhatsappPageProps = {
  socket: Socket;
};

export const formatPhoneNumber = (phone: string) => {
  return phone.startsWith("+") ? phone.slice(1) : phone;
};

const WhatsappPage: React.FC<WhatsappPageProps> = ({ socket }) => {
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>();
  const [currentStep, setCurrentStep] = useState<number>(0);
  const [base64Url, setBase64Url] = useState<string | undefined>();
  const [existingSession, setExistingSession] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSessionInit, setSessionInit] = useState<boolean>(false);
  const [isScanning, setIsScanning] = useState(false); // Track loading state after QR is scanned
  const [view, setView] = useState<"main" | "whatsapp" | "messageGroup"| "contacts">("main");

  const { currentUser, loading } = useSelector(
    (state: RootState) => state.auth
  );

  const hasActiveSubscription = useCallback(
    () => currentUser?.activeSubscriptions,
    [currentUser]
  );

  const initSession = async (formattedPhoneNumber: string) => {
    setIsLoading(true);
    const userPackageId = currentUser?.activeSubscriptions?.userPackageId;
    if (!userPackageId) {
      return;
    }
    try {
      const response = await whatsappService.initSession(
        formattedPhoneNumber,
        userPackageId
      );
      return response;
    } catch (error) {
      toast.error(
        "An error occurred during session initialization. Please try again later."
      );
      return null;
    } finally {
      setIsLoading(false);
    }
  };

  const handleInitializeSession = useCallback(async () => {
    if (!phoneNumber) {
      toast.error("Please enter a phone number.");
      return;
    }

    const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
    const response = await initSession(formattedPhoneNumber);

    if (response) {
      if (response.status === -1) {
        toast.error(response.message);
      } else if (response.data) {
        setBase64Url(response.data);
        toast.success("Session initialized successfully!");
        setCurrentStep(1);
        setExistingSession(false);
      } else {
        toast.success("You already have an active session!");
        setExistingSession(true);
        setSessionInit(true);
      }
    }
  }, [phoneNumber]);

  const handleBackToMain = () => setView("main");
  const handleBackToInit = () => {
    setSessionInit(false);
    setPhoneNumber(undefined);
  };

  const handleNavigate = (action: "whatsapp" | "messageGroup" | "contacts") => {
    setView(action);
  };

  const handleBackStep = () => {
    setCurrentStep(0);
    setSessionInit(false);
  };

  const handleReuseSession = (session: Session) => {
    setPhoneNumber(`+${session.phoneNumber}`);
    if (!session.status) {
      return;
    }
    setExistingSession(true);
    setSessionInit(true);
  };

  useEffect(() => {
    const handleWhatsappStatus = (data: any) => {
      logMessage(data);
      if (data.message === "Session status: qrReadSuccess") {
        setIsScanning(true);
      }
      if (data.message === "Session status: inChat") {
        setIsScanning(false);
        setSessionInit(true);
        logMessage("WhatsApp session is now in chat.");
      }
    };

    socket.on("whatsapp_status", handleWhatsappStatus);

    return () => {
      socket.off("whatsapp_status", handleWhatsappStatus);
    };
  }, [socket]);

  // New useEffect to handle conditional listening for the "disconnected mobile" event
  useEffect(() => {
    const handleWhatsappDisconnectedMobile = () => {
      setSessionInit(false);
      setView("main");
      setCurrentStep(0);
      toast.loading(
        "WhatsApp disconnected from mobile. Returning to main menu.",
        { duration: 3000 }
      );
    };

    // Listen for the event only when the view is "whatsapp" or "contacts"
    if (view === "whatsapp" || view === "messageGroup" || view === "contacts") {
      socket.on(
        SOCKET_EVENTS.WHATSAPP_DISCONNECTED_MOBILE,
        handleWhatsappDisconnectedMobile
      );
    }

    // Clean up the event listener when the view changes
    return () => {
      socket.off(
        SOCKET_EVENTS.WHATSAPP_DISCONNECTED_MOBILE,
        handleWhatsappDisconnectedMobile
      );
    };
  }, [socket, view]);

  return (
    <AuthLayout>
      <VerifiedGuard>
        <main className="nxl-container apps-container apps-notes apps-email">
          <div className="nxl-content">
            <div className="page-header">
              {view !== "main" && (
                <button
                  className="btn btn-light-brand p-1 px-3 me-3"
                  onClick={handleBackToMain}
                >
                  <UilArrowLeft />
                  Back to menu
                </button>
              )}
              {isSessionInit && view === "main" && (
                <button
                  className="btn btn-light-brand p-1 px-3 me-3"
                  onClick={handleBackToInit}
                >
                  <UilArrowLeft />
                  Back
                </button>
              )}
              <div className="page-header-left d-flex align-items-center">
                <div className="page-header-title">
                  <h5 className="m-b-10 border-0">
                    Manage WhatsApp: Connect, Message, Extract
                  </h5>
                </div>
              </div>
            </div>
            <div className="main-content bg-white">
              <div
                className="content-area"
                data-scrollbar-target="#psScrollbarInit"
              >
                {loading ? (
                  <div className="col-md-8 mx-auto">
                    <Stack spacing={1}>
                      {/* For variant="text", adjust the height via font-size */}
                      <Skeleton variant="text" sx={{ fontSize: "1rem" }} />

                      {/* For other variants, adjust the size with `width` and `height` */}
                      <Skeleton variant="circular" width={40} height={40} />
                      <Skeleton
                        variant="rectangular"
                        className="w-100"
                        height={60}
                      />
                      <Skeleton
                        variant="rounded"
                        className="w-100"
                        height={60}
                      />
                    </Stack>
                  </div>
                ) : !hasActiveSubscription() ? (
                  <div className="mx-auto d-flex flex-column justify-content-center align-items-center">
                    <h3>Welcome!</h3>
                    <p className="mb-1 text-black fs-14">
                      It looks like you don't have an active subscription at the
                      moment.
                    </p>
                    <p className="fs-14 mb-0">
                      To start sending messages via WhatsApp API, please choose
                      a subscription plan that best suits your needs.
                    </p>
                    <Link to="/subscribe" className="btn btn-primary mt-3">
                      Subscribe Now
                    </Link>
                  </div>
                ) : (
                  <div>
                    {view === "main" && !isSessionInit && (
                      <div className="">
                        <CreateSession
                          currentStep={currentStep}
                          isLoading={isLoading}
                          existingSession={existingSession}
                          isScanning={isScanning}
                          phoneNumber={phoneNumber}
                          base64Url={base64Url}
                          setPhoneNumber={setPhoneNumber}
                          handleInitializeSession={handleInitializeSession}
                          handleBackStep={handleBackStep}
                        />
                        <h5 className="text-center">OR</h5>
                        <h5>Use Recent Sessions</h5>
                        <UserWhatsappSessions
                          handleReuseSession={handleReuseSession}
                        />
                      </div>
                    )}

                    {isSessionInit && view === "main" && (
                      <div className="row justify-content-center">
                        <div className="col-md-4">
                          <div className="card stretch stretch-full">
                            <div className="card-body d-flex flex-column">
                              <UilWhatsapp
                                size="50"
                                className="text-green mx-auto title-icons"
                              />
                              <span className="text-center text-black my-1 fs-14">
                                Send WhatsApp Messages
                              </span>
                              <button
                                className="btn btn-primary mt-auto"
                                onClick={() => handleNavigate("whatsapp")}
                              >
                                Send Message
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="card stretch stretch-full">
                            <div className="card-body d-flex flex-column">
                              <UilUsersAlt 
                                size="50"
                                className="text-green mx-auto title-icons"
                              />
                              <span className="text-center text-black my-1 fs-14">
                                WhatsApp Group Chat
                              </span>
                              <button
                                className="btn btn-primary mt-auto"
                                onClick={() => handleNavigate("messageGroup")}
                              >
                                Send Message
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="card stretch stretch-full">
                            <div className="card-body d-flex flex-column">
                              <UilUsersAlt
                                size="50"
                                className="text-info mx-auto title-icons"
                              />
                              <span className="text-center text-black my-1 fs-14">
                                Extract Contacts from WhatsApp
                              </span>
                              <button
                                className="btn btn-primary mt-auto"
                                onClick={() => handleNavigate("contacts")}
                              >
                                Extract Contacts
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {view === "whatsapp" && (
                      <div className="d-flex flex-column">
                        <WhatsappMessage phoneNumber={phoneNumber} />
                      </div>
                    )}
                    {view === "messageGroup" && (
                      <div className="d-flex flex-column">
                        <WhatsappMessageGroup phoneNumber={phoneNumber} />
                      </div>
                    )}
                    {view === "contacts" && (
                      <div className="d-flex flex-column">
                        <ExtractContacts
                          phoneNumber={phoneNumber}
                          packageId={
                            currentUser?.activeSubscriptions?.packageId
                          }
                          remainingExtractions={
                            currentUser?.activeSubscriptions
                              ?.remainingExtractions
                          }
                          userHasBasic={
                            currentUser?.activeSubscriptions?.price === 0
                          }
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </main>
      </VerifiedGuard>
    </AuthLayout>
  );
};

export default WhatsappPage;
