import { CreateSms, SendMessage, SendMessageToGroup } from "../models/SMS";
import axiosInstance from "./axiosInstance";

const sendSmsToGroup = async (data: SendMessageToGroup, groupCode: string) => {
  const response = await axiosInstance.post(
    `task-sms/send-to-group/${groupCode}`,
    data
  );
  return response.data;
};

const sendSmsToContacts = async (data: CreateSms) => {
  const response = await axiosInstance.post(`task-sms/contact/send`, data);
  return response.data;
};

const getAllSms = async (params: {
  page?: number;
  limit?: number;
  filters?: any;
}) => {
  const response = await axiosInstance.get("task-sms/all", { params });
  return response.data;
};

const getAvailableCountries = async () => {
  const response = await axiosInstance.get("task-sms/available/country");
  return response.data;
};
const adminGetAvailableCountries = async () => {
  const response = await axiosInstance.get("task-sms/config/available/country");
  return response.data;
};

const sendMessage = async (data: SendMessage) => {
  const response = await axiosInstance.post(`task-sms/send`, data);
  return response.data;
};

const ResendMessage = async (smsCode: string) => {
  const response = await axiosInstance.post(
    `task-sms/not-delivered/${smsCode}`
  );
  return response.data;
};

const getSmsType = async () => {
  const response = await axiosInstance.get("task-sms/types");
  return response.data;
};

export const smsService = {
  sendSmsToGroup,
  sendSmsToContacts,
  getAllSms,
  sendMessage,
  ResendMessage,
  getAvailableCountries,
  getSmsType,
  adminGetAvailableCountries
};
