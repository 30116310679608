import { Navigate} from "react-router-dom";

const RequireOtpGuard = ({ children }: { children: JSX.Element }) => {
  const otpConfirmed = localStorage.getItem("otpConfirmed") === "true";

  if (!otpConfirmed) {
    return <Navigate to="/auth/verification" replace/>;
  }

  return children;
};

export default RequireOtpGuard;
