import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { Contact, ContactGroup } from "../../models/Contacts";
import { whatsappService } from "../../services/whatsappService";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { getAllWhatsappContacts } from "../../redux/slices/contactSlice";
import { customStyles, OptionType } from "../../pages/app/SingleSMS";
import { logMessage } from "../../utils/logMessage";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { formatForWhatsApp, getTextFromHtml } from "../../utils/functions";
import ImageUpload from "./ImageUpload";
import { getAllContactGroups} from "../../redux/slices/contactGroupSlice";
import makeAnimated from "react-select/animated";

type WhatsappMessageProps = {
  phoneNumber: string | undefined;
};

const WhatsappMessageGroup: React.FC<WhatsappMessageProps> = ({ phoneNumber }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isUploaded, setIsUploaded] = React.useState(false);
  const [file, setFile] = useState<File | null>(null);
  const [editorHtml, setEditorHtml] = useState<string>("");
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [groups, setGroups] = useState<ContactGroup[]>([]);
  // const [groupContacts, setGroupContacts] = useState<ContactGroup[]>([]);
  const [groupContacts, setGroupContacts] = useState<Contact[]>([]);

  const animatedComponents = makeAnimated();

  const quillRef = useRef<ReactQuill>(null);
  const dispatch: AppDispatch = useDispatch();

  const contacts = useSelector(
    (state: RootState) => state.contacts.whatsappContacts
  );

  const handleImageChange = (file: File | null) => {
    setSelectedImage(file);
  };

  const transformGroupsToOptions = (groups: ContactGroup[]): OptionType[] => {
    return groups.map((group) => ({
      value: group.group_code,
      label: group.group_name,
    }));
  };

  const isEditorEmpty = (html: string) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;

    // Remove whitespace and check if there's any meaningful text
    const textContent = tempDiv.textContent || "";

    // Remove HTML tags and check if the remaining content is empty
    return (
      textContent.trim() === "" ||
      textContent.replace(/[\n\r]+/g, "").trim() === ""
    );
  };

  const handleSubmit = async () => {
    // Early returns for invalid input
    if (!phoneNumber) {
      toast.error("Invalid Phone Number");
      return;
    }
    if (!editorHtml) {
      toast.error("Please enter your message content");
      return;
    }
    if (isUploaded && !file) {
      toast.error("Please select a file containing your contacts.");
      return;
    }
    if (!isUploaded && groupContacts.length === 0) {
      toast.error("You need to select a group with contacts.");
      return;
    }

    // Format the phone number by removing the '+' sign
    const formattedPhoneNumber = phoneNumber.startsWith("+")
      ? phoneNumber.slice(1)
      : phoneNumber;

    const formattedMessage = formatForWhatsApp(editorHtml);

    try {
      setIsLoading(true);

      // Create data object for the request
      const data = {
        phoneNumber: formattedPhoneNumber,
        message: formattedMessage,
        contactsObj: groupContacts,
      };

      let response;

      if (selectedImage) {
        // Create FormData only if there's a file to upload
        const formData = new FormData();
        formData.append("message", formattedMessage);
        formData.append("phoneNumber", formattedPhoneNumber);
        formData.append("contactsObj", JSON.stringify(groupContacts));
        formData.append("image", selectedImage);

        response = await whatsappService.sendMessageWithImage(formData);
      } else {
        // Send regular message without file
        response = await whatsappService.sendMessageToContacts(data);
      }

      // Handle response
      if (response.status === -1) {
        toast.error(response.message);
      } else {
        toast.success(response.message);
        resetForm(); // Batch state updates by moving them into one function
      }
    } catch (error) {
      toast.error("An error occurred while sending your message");
      logMessage(error);
    } finally {
      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      to: null as OptionType | null,
      message: "",
    },
    validationSchema: Yup.object({
      to: Yup.object().nullable().required("Required"),
      message: Yup.string()
        .max(500, "Message must be at most 500 characters")
        .required("Required"),
    }),

    onSubmit: handleSubmit,
  });

  // Helper function to reset form fields
  const resetForm = () => {
    setEditorHtml("");
    setIsUploaded(false);
    setSelectedImage(null);
    setGroupContacts([]);
  };

  const handleChange = (content: string) => {
    const textContent = getTextFromHtml(content);
    if (textContent.length <= 1000) {
      setEditorHtml(content);
    } else {
      setEditorHtml(content.substring(0, 1000)); // Trim excess characters
    }
  };


  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const res = await dispatch(getAllContactGroups()).unwrap();
        setGroups(res || []);
      } catch (err) {
        setGroups([]);
      }
    };

    fetchGroups();
  }, [dispatch]);

  const handleGroupChange = async (selectedOption: OptionType | null) => {
    if (!selectedOption) {
      setGroupContacts([]);
      return;
    }
  
    try {
      const res: ContactGroup[] = await dispatch(getAllContactGroups()).unwrap();
  
      const selectedGroup = res.find(group => group.group_code === selectedOption.value);
  
      if (selectedGroup && selectedGroup.whatsappContactJson) {
        setGroupContacts(selectedGroup.whatsappContactJson);
      } else {
        setGroupContacts([]);
      }
  
    } catch (err) {
      setGroupContacts([]);
    }
  };
  

  const options: OptionType[] = [...transformGroupsToOptions(groups)];

  return (
    <div className="row">
      <div className="mb-4 col-12 align-items-center justify-content-between">
        <h5 className="fw-bold mb-0 me-4">
          <span className="d-block mb-2">
            Select a Group to Send Message to Its Contacts.
          </span>
          <span className="fs-12 fw-normal text-muted text-truncate-1-line">
            Note that the message will be sent to all contacts in the selected group.
          </span>
        </h5>
      </div>
      <div className="step-content col-md-8">
        <div className="d-flex flex-column gap-2">
          <div className="text-primary fw-bold">
            {groupContacts.length !== 0
              ? `Note : Message will be sent to ${groupContacts.length} contacts`
              : ""}
          </div>
          <div className="contact">
            <div className="mb-2">
              <Select<OptionType>
                components={animatedComponents}
                options={options || []}
                className="w-full border-0 text-dark"
                placeholder="Select Group"
                styles={customStyles}
                required
                id="to"
                name="to"
                value={formik.values.to}
                onChange={(selectedOption) => {
                  formik.setFieldValue("to", selectedOption);
                  handleGroupChange(selectedOption);
                }}
                onBlur={formik.handleBlur}
              />
            </div>
          </div>
        </div>
        <div className="items-details-footer mail-action-editor mt-3 border border-top-0 rounded-3">
          <div
            className="p-0  border-top position-relative editor-section"
            data-scrollbar-target="#psScrollbarInit"
          >
            <div className="h-100 border-0" id="mailEditor">
              <ReactQuill
                ref={quillRef}
                value={editorHtml}
                onChange={handleChange}
                className="h-100"
                theme="snow"
                modules={{
                  toolbar: [
                    ["bold", "italic"],
                    [{ list: "ordered" }, { list: "bullet" }],
                    ["clean"],
                  ],
                }}
                formats={["header", "bold", "italic", "list"]}
              />
            </div>
          </div>
        </div>
        <div className="text-muted mt-2 text-end">
          {getTextFromHtml(editorHtml).length} / 1000 characters
        </div>
        <div className="d-flex align-items-center gap-2 mt-3 justify-content-between">
          <button
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={
              isLoading ||
              isEditorEmpty(editorHtml) ||
              groupContacts.length === 0
            }
          >
            {isLoading && <span className="loader"></span>}
            {isLoading ? "Sending..." : "Send Message"}
          </button>
        </div>
      </div>
      <div className="col-md-4 py-2">
        <ImageUpload
          onFileChange={handleImageChange}
          selectedImage={selectedImage}
        />
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/mZr2GsLI8PM"
          title="WhatsApp Messaging Tutorial"
          style={{ height: "200px" }}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default WhatsappMessageGroup;